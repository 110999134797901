import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import CurveTop from "../components/svg/curveTop"
import ArrowDown from "../components/svg/arrowDown"
import ThreeArrows from "../components/svg/threeArrows"
import Complex from "../components/svg/complex"
import Consulting from "../components/svg/consulting"
import Coaching from "../components/svg/coaching"
import Check from "../components/svg/check"
import Benchmark from "../components/svg/benchmark"
import scrollTo from 'gatsby-plugin-smoothscroll'
import Quotes from "../components/svg/quotes"
import Testimonial from "../components/testimonial"
import Cta from "../components/cta"

const FmtPage = () => (

  	<Layout>

      <Seo 
         title="Faulkner Media Training | Madclarity"
         description="The origins of Faulkner Media Training, created by Eric Faulkner, and the creation of Madclarity's Media Skill and Capability training programme." 
      />

<section role="main" className="bg">
         <div className="container">
            <div className="row">
               <div className="sub-hero-content sub-hero">
                  <div className="sub-hero-text full">
                     <h1
                        data-sal="slide-up" 
                        data-sal-delay="200"
                        data-sal-duration="500"
                     >Faulkner Media Training</h1>
                     <div
                        data-sal="slide-up" 
                        data-sal-delay="400"
                        data-sal-duration="500"
                     >
                        <p>Read about the origins of Faulkner Media Training and the creation of Madclarity's Media Skill and Capability training programme.</p>
                     </div>
                     <div
                        className="btn-row"
                        data-sal="slide-up"
                        data-sal-delay="600"
                        data-sal-duration="500"
                     >
                        <button
                           onClick={() => scrollTo("#anchor")}
                           className="btn-scroll"
                        >
                           <ArrowDown />
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <div className="curve-btm">
         <CurveTop />
      </div>

      <section className="section-pad" id="anchor">
         <div className="container">
            <div className="row">
                <div className="cols-2-wrapper is-top">
                    <div className="col-2">
                        <div className="col-2-img"
                           data-sal="zoom-in" 
                           data-sal-delay="50"
                           data-sal-duration="200"
                        >
                            <Coaching />
                        </div>
                    </div>
                    <div className="col-2"
                     data-sal="slide-up" 
                     data-sal-delay="75"
                     data-sal-duration="200"
                    >
                        <h2>Media Skill & Capability Development ... <span className="blue">“I want the team to Understand this better”</span></h2>
                        <p className="lead">The advertising media world is made to look increasingly and unnecessarily complex and confusing. Simple questions are often met with baffling and jargon-filled responses, discouraging Marketers from engaging in constructive discussions with their Advertising Agencies.</p>
                    </div>
                </div>
            </div>
         </div>
      </section>

      <section id="benchmarking">
         <div className="container">
            <div className="row">
                <div className="cols-2-wrapper is-top">
                    <div className="col-2 order-2-mob"
                     data-sal="slide-up" 
                     data-sal-delay="75"
                     data-sal-duration="200"
                    >
                        <h3>Origins: <span className="blue">a new advertising training programme</span></h3>
                        <p className="lead">A number of years ago, Eric Faulkner developed a unique media training programme for Marketers. People who went to those workshops are now CMOs and Board Members, not only in Australia, but across SE Asia and even in the UK and US. Many have kept the magic A5 <strong>Faulkner Media Training</strong> books that accompanied the programme.</p>
                        <p className="">When Faulkner set up Madclarity with Mark Leone and Noel Jones, he ensured that developing a brand new <a href="/what-we-do">advertising training programme</a> was top of the list. We now have a contemporary Faulkner Media Training programme, designed to build Media Skill and Capability.</p>
                        <p className="">It combines all the enduring principles that every top marketer needs to understand, along with contemporary media developments ... honestly and critically explained.</p>
                    </div>
                    <div className="col-2 order-1-mob">
                        <div className="col-2-img"
                           data-sal="zoom-in" 
                           data-sal-delay="50"
                           data-sal-duration="200"
                        >
                            <Consulting />
                        </div>
                    </div>
                </div>
            </div>
         </div>
      </section>

      <section className="section-pad" id="coaching">
         <div className="container">
            <div className="row">
                <div className="cols-2-wrapper is-top">
                    <div className="col-2">
                        <div className="col-2-img"
                           data-sal="zoom-in" 
                           data-sal-delay="50"
                           data-sal-duration="200"
                        >
                            <Benchmark />
                        </div>
                    </div>
                    <div className="col-2"
                     data-sal="slide-up" 
                     data-sal-delay="75"
                     data-sal-duration="200"
                    >
                        <h3>A coaching programme to <span className="blue">build confidence</span></h3>
                        <p className="lead">Just like Faulkner Media Training, Madclarity’s coaching programme is designed to build confidence to interact directly & constructively with the media agency ... questioning anything that is unclear or potentially misleading.</p>
                        <p className="">We now find that running a series of shorter* workshop sessions and providing an on-going programme produces even better outcomes. Our new approach comprises a programme of seven coaching workshops that cover each key stage of the Comms process. They are spread over a period of several weeks, with opportunities to test out the learning between workshops, share experiences and raise questions.</p>
                        <p className="">The interactive sessions (Face-to-Face or on Zoom) include experience-based stories and case histories and practical exercises They also include group work with short break-out sessions to evaluate briefs, Agency strategy recommendations and media plans.</p>
                        <p className="">Whilst the focus is on the Media side of the Comms process, we also run sessions on briefing Creative teams, evaluating their responses and providing constructive feedback. And we run sessions on how best to use OOH advertising ... especially from a Creative perspective.</p>
                        <p className="">We provide bound, illustrated A5 Madclarity books, which cover the key points for each stage of the process along with check-lists.</p>
                        <div>* 90 mins / Two hours</div>
                    </div>
                </div>
            </div>
         </div>
      </section>

      <section className="section-pad" id="process">
         <div className="container">
            <div className="row">
                <div className="cols-2-wrapper">
                    <div className="col-2 order-2-mob"
                     data-sal="slide-up" 
                     data-sal-delay="75"
                     data-sal-duration="200"
                    >
                        <h4>Media <span className="blue">check-lists</span></h4>
                        <p className="lead">We have found that check-lists are the simplest and easiest-to-follow ways to ensure that learning is put into practice and to improve performance in the real world.</p>
                        <p className="">They complement, rather than compete with, any company templates that are being used. They are designed to make marketers think. They are included in our Madclarity illustrated and bound A5 workshop books.</p>
                    </div>
                    <div className="col-2 order-1-mob">
                        <div className="col-2-img"
                           data-sal="zoom-in" 
                           data-sal-delay="50"
                           data-sal-duration="200"
                        >
                            <Complex />
                        </div>
                    </div>
                </div>
            </div>
         </div>
      </section>

      <section className="section-pad" id="coaching">
         <div className="container">
            <div className="row">
                <div className="cols-2-wrapper is-top">
                    <div className="col-2">
                        <div className="col-2-img"
                           data-sal="zoom-in" 
                           data-sal-delay="50"
                           data-sal-duration="200"
                        >
                            <ThreeArrows />
                        </div>
                    </div>
                    <div className="col-2"
                     data-sal="slide-up" 
                     data-sal-delay="75"
                     data-sal-duration="200"
                    >
                        <h4>The <span className="blue">Madclarity Comms Development Programme</span> covers:</h4>
                        <ul className="list-unstyled">
                            <li><span className="check"><Check /></span> A clear and workable comms process</li>
                            <li><span className="check"><Check /></span> How to identify the Brand Problem</li>
                            <li><span className="check"><Check /></span> Developing a clear and focussed brief</li>
                            <li><span className="check"><Check /></span> Encouraging agency engagement & understanding of the brief</li>
                            <li><span className="check"><Check /></span> Evaluating the Agency response and encouraging The Idea</li>
                            <li><span className="check"><Check /></span> Understanding the plan and ensuring implementation is based on the strategy</li>
                            <li><span className="check"><Check /></span> Evaluating and learning how to do it even better next time</li>
                        </ul>
                        <p className="">Workshops will be tailored to your needs, with your briefs, strategies, plans & post-campaign reports.</p>
                        <p className="">We also offer specialist workshops including:</p>
                        <ul className="list-unstyled">
                            <li><span className="check"><Check /></span> Making OOH work ... Media and Creative</li>
                            <li><span className="check"><Check /></span> Evaluating Creative … and giving feedback</li>
                        </ul>
                    </div>
                </div>
            </div>
         </div>
      </section>

      <div className="curve-top flip">
         <CurveTop />
      </div>
      <section className="bg testimonials">
         <div className="container">
            <div className="row">
               <div className="testimonial-col-wrapper">
                  <div className="testimonial-col"
                     data-sal="fade" 
                     data-sal-delay="200"
                     data-sal-duration="400"
                  >
                     <Quotes />
                     <div className="test-excerpt">
                        ... tough questions ...
                        <span className="blue"> workable solutions</span> ...
                     </div>
                     <Testimonial
                        quote="Madclarity plays a vital part in monitoring and directing our media investment strategy. They ask the tough questions and through their knowledge base, always provide viable and workable solutions for improvements."
                        name="Scott Baird"
                        title="Chief Marketing Officer, Hungry Jack’s Pty Ltd"
                     />
                  </div>
               </div>
            </div>
         </div>
      </section>
      <div className="curve-btm flip">
         <CurveTop />
      </div>
      
      <Cta />

  </Layout>
)

export default FmtPage